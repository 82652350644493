<template>
  <v-col
    cols="12"
    sm="12"
  >
    <v-toolbar
      flat
      color="accent"
    >
      <v-spacer />
      <export-excel :section="18" />
    </v-toolbar>

    <v-tabs
      v-model="tab"
      grow
      show-arrows="mobile"
      background-color="transparent"
      @change="tabChange(tab)"
    >
      <v-tab>Researchers</v-tab>

      <v-tab-item>
        <v-card
          flat
          color="accent"
        >
          <users-researchers
            :key="refreshResearchers + usersStore.activeUser.id + usersStore.activeProject.id"
            :section="5"
          />
        </v-card>
      </v-tab-item>

      <v-tab>Trainees</v-tab>

      <v-tab-item>
        <v-card
          flat
          color="accent"
        >
          <users-trainees
            :key="refreshTrainees + usersStore.activeUser.id + usersStore.activeProject.id"
            :section="6"
          />
        </v-card>
      </v-tab-item>

      <v-tab>Other Project Users</v-tab>

      <v-tab-item>
        <v-card
          flat
          color="accent"
        >
          <users-other-users
            :key="refreshOtherUsers + usersStore.activeUser.id + usersStore.activeProject.id"
            :section="7"
          />
        </v-card>
      </v-tab-item>

      <v-tab>Other RPPR Participants</v-tab>
      <v-tab-item>
        <v-card
          flat
          color="accent"
        >
          <users-rppr-admin
            :key="refreshRppr + usersStore.activeUser.id + usersStore.activeProject.id"
            :section="8"
          />
        </v-card>
      </v-tab-item>


      <v-tab>Cumulative Key Outcomes</v-tab>
      <v-tab-item>
        <v-card
          flat
          color="accent"
        >
          <key-outcome />
        </v-card>
      </v-tab-item>

      <!--Researchers-->
      <!--Trainees-->
      <!--Other Users-->
      <!--Other RPPR Participants-->
      <!--Cumulative Key Outcomes-->
    </v-tabs>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import UsersResearchers from '@/components/UsersResearchers';
import UsersTrainees from '@/components/UsersTrainees';
import UsersOtherUsers from '@/components/UsersOtherUsers';
import UsersRpprAdmin from '@/components/UsersRpprAdmin';
import KeyOutcome from '@/components/KeyOutcome';
import ExportExcel from '@/components/ExportExcel'

export default {
  name: 'Administrator',
  components: {
    UsersResearchers,
    UsersTrainees,
    UsersOtherUsers,
    UsersRpprAdmin,
    KeyOutcome,
    ExportExcel,
  },
  mixins: [mixin],
  data() {
	  return {
      tab:0,
      refreshResearchers:0,
      refreshTrainees:0,
      refreshOtherUsers: 0,
      refreshRppr: 0,
	  };
  },
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
    }),
  },
  methods: {
    tabChange(tab) {
      if(tab === 0) {
        this.refreshResearchers++
      }
      else if(tab === 1) {
        this.refreshTrainees++
      }
      else if(tab === 2) {
        this.refreshOtherUsers++
      }
      else if(tab === 3) {
        this.refreshRppr++
      }
    }
  }
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
