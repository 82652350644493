<template>
  <v-col
    v-if="progressStore.progress !== true"
    cols="12"
    sm="12"
  >
    <v-toolbar
      class="pl-0"
      flat
      dense
      color="accent"
    >
      <v-spacer />
    </v-toolbar>

    <v-container>
      <v-row class="pb-6">
        <v-col
          cols="12"
          sm="12"
        >
          <span class="text-h5">All Proposals</span>

          <!--Total # Submitted-->
          <text-display
            :display-number="projectsStore.keyOutcomes.proposalsSubmitted > 0 ? projectsStore.keyOutcomes.proposalsSubmitted : 0"
            :label="'Total # Submitted'"
            :side-label="true"
          />
                
          <!--Total $ Submitted-->
          <text-display
            :display-string="$_formatMoney(projectsStore.keyOutcomes.proposalsDollarSubmitted > 0 ? projectsStore.keyOutcomes.proposalsDollarSubmitted : 0)"
            :label="'Total $ Submitted'"
            :side-label="true"
          />
                
          <!--Total # Funded-->
          <text-display
            :display-number="projectsStore.keyOutcomes.proposalsFunded > 0 ? projectsStore.keyOutcomes.proposalsFunded : 0"
            :label="'Total # Funded'"
            :side-label="true"
          />

          <!--Total $ Funded-->
          <text-display
            :display-string="$_formatMoney(projectsStore.keyOutcomes.proposalsDollarFunded > 0 ? projectsStore.keyOutcomes.proposalsDollarFunded : 0)"
            :label="'Total $ Funded'"
            :side-label="true"
          />
        </v-col>
      </v-row>

      <v-divider />

      <v-row class="py-6">
        <v-col
          cols="12"
          sm="12"
        >
          <span class="text-h5">NSF Proposals</span>
          <!--Total # Submitted-->
          <text-display
            :display-number="projectsStore.keyOutcomes.proposalsSubmittedNSF > 0 ? projectsStore.keyOutcomes.proposalsSubmittedNSF : 0"
            :label="'Total # Submitted'"
            :side-label="true"
          />
                
          <!--Total $ Submitted NSF-->
          <text-display
            :display-string="$_formatMoney(projectsStore.keyOutcomes.proposalsDollarSubmittedNSF > 0 ? projectsStore.keyOutcomes.proposalsDollarSubmittedNSF : 0)"
            :label="'Total $ Submitted'"
            :side-label="true"
          />
                
          <!--Total # Funded NSF-->
          <text-display
            :display-number="projectsStore.keyOutcomes.proposalsFundedNSF > 0 ? projectsStore.keyOutcomes.proposalsFundedNSF : 0"
            :label="'Total # Funded'"
            :side-label="true"
          />

          <!--Total $ Funded NSF-->
          <text-display
            :display-string="$_formatMoney(projectsStore.keyOutcomes.proposalsDollarFundedNSF > 0 ? projectsStore.keyOutcomes.proposalsDollarFundedNSF : 0)"
            :label="'Total $ Funded'"
            :side-label="true"
          />
        </v-col>
      </v-row>

      <v-divider />

      <v-row class="py-6">
        <v-col
          cols="12"
          sm="12"
        >
          <span class="text-h5">Publications</span>

          <!--Total # Journal Articles Print-->
          <text-display
            :display-number="projectsStore.keyOutcomes.publicationJournal > 0 ? projectsStore.keyOutcomes.publicationJournal : 0"
            :label="'Total # Published Journal Articles - Print'"
            :side-label="true"
          />

          <!--Total # Journal Articles Electronic Only-->
          <text-display
            :display-number="projectsStore.keyOutcomes.publicationJournalOnline > 0 ? projectsStore.keyOutcomes.publicationJournalOnline : 0"
            :label="'Total # Published Journal Articles - Electronic Only'"
            :side-label="true"
          />
                
          <!--Total # Conference Proceedings-->
          <text-display
            :display-number="projectsStore.keyOutcomes.publicationConference > 0 ? projectsStore.keyOutcomes.publicationConference : 0"
            :label="'Total # Published Conference Proceedings'"
            :side-label="true"
          />
                
          <!--Total # Book Chapters-->
          <text-display
            :display-number="projectsStore.keyOutcomes.publicationChapter > 0 ? projectsStore.keyOutcomes.publicationChapter : 0"
            :label="'Total # Published Book Chapters'"
            :side-label="true"
          />

          <!--Total # Books-->
          <text-display
            :display-number="projectsStore.keyOutcomes.publicationBook > 0 ? projectsStore.keyOutcomes.publicationBook : 0"
            :label="'Total # Published Books'"
            :side-label="true"
          />
        </v-col>
      </v-row>

      <v-divider />

      <v-row class="py-6">
        <v-col
          cols="12"
          sm="12"
        >
          <span class="text-h5">Patents</span>
                
          <!--Total # Submitted-->
          <text-display
            :display-number="projectsStore.keyOutcomes.patentSubmitted > 0 ? projectsStore.keyOutcomes.patentSubmitted : 0"
            :label="'Total # Submitted'"
            :side-label="true"
          />
                
          <!--Total # Not Awarded-->
          <text-display
            :display-number="projectsStore.keyOutcomes.patentNotAwarded > 0 ? projectsStore.keyOutcomes.patentNotAwarded : 0"
            :label="'Total # Not Awarded'"
            :side-label="true"
          />

          <!--Total #Awarded-->
          <text-display
            :display-number="projectsStore.keyOutcomes.patentAwarded > 0 ? projectsStore.keyOutcomes.patentAwarded : 0"
            :label="'Total #Awarded'"
            :side-label="true"
          />
        </v-col>
      </v-row>

      <v-divider />

      <v-row class="pt-6">
        <v-col
          cols="12"
          sm="12"
        >
          <span class="text-h5">Presentations</span>
                
          <!--Total # Submitted-->
          <text-display
            :display-number="projectsStore.keyOutcomes.presentationCount > 0 ? projectsStore.keyOutcomes.presentationCount : 0"
            :label="'Total # Published'"
            :side-label="true"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';
import TextDisplay from '@/components/TextDisplay';

export default {
  name: 'KeyOutcomes',
  components: {
    TitleBar,
    TextDisplay,
  },
  mixins: [mixin],
  data() {
	  return {
	  	
	  };
  },
  computed: {
    ...mapState({
      projectsStore: state => state.projectsStore,
      progressStore: state => state.progressStore,  
    }),
  },
  created() {
    this.initialize()
  },
  methods: {
    async initialize() {
      this.$store.dispatch('progressStore/set',true)
      let project = this.$store.getters['usersStore/getActiveProject']
      let data = {
        id_project: project.id,
      }
      await this.$store.dispatch('projectsStore/fetchKeyOutcomes', data)
      this.$store.dispatch('progressStore/set',false)
    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
