<template>
  <div>
    <!-- Filter -->
    <v-toolbar
      flat
      dense
      color="accent"
    >
      <!--  Filter Icon -->
      <v-menu
        v-model="filterMenu"
        offset-y
        right
        :close-on-content-click="false"
      >
        <template #activator="{ on: menu }">
          <v-tooltip
            right
            color="primary"
          >
            <template #activator="{ on: tooltip }">
              <v-btn
                icon
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon
                  v-if="showFiltered()"
                  color="primary"
                >
                  fas fa-filter
                </v-icon>
                <v-icon
                  v-else
                  color="primary"
                >
                  fal fa-filter
                </v-icon>
              </v-btn>
            </template>
            <span>Filter</span>
          </v-tooltip>
        </template>

        <v-card width="400px">
          <v-card-title>
            <v-spacer />
            <v-btn
              v-if="showFiltered()"
              text
              rounded
              outlined
              block
              @click="clearFilter()"
            >
              <v-icon
                small
                left
              >
                fal fa-times
              </v-icon>Clear Filters
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <!----------RPPR List---------->
              <v-row dense>
                <!--User ID-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="rppr_id"
                    label="User ID"
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--First Name-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="rppr_name_first"
                    label="First Name"
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Last Name-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="rppr_name_last"
                    label="Last Name"
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Email-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="email"
                    label="Email"
                    placeholder=" "
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="mx-4 pb-4">
            <v-btn
              block
              rounded
              color="primary"
              @click="filter()"
            >
              <v-icon
                small
                left
              >
                fal fa-check
              </v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <!--Pagination  -->
      <pagination
        :show="usersStore.rppr.data && usersStore.rppr.data.length > 0 && usersStore.rppr.last_page > 1 ? true : false"
        :current-page="usersStore.rppr.current_page"
        :last-page="usersStore.rppr.last_page"
        @paginate-event="filter($event)"
      />

      <v-spacer />

      <!--Export Excel-->
      <export-excel
        :section="5"
        :sub-section="section"
      /> 

      <!--Entry Dialog-->
      <participation-entry 
        v-if="1 == 2"
        :add-new="1"
        :section="section" 
      />

      <user :section="section" />
    </v-toolbar>
    
    <div v-if="progressStore.progress !== true">
      <!--Table-->
      <v-simple-table
        v-if="usersStore.rppr.data && usersStore.rppr.data.length > 0"
        :fixed-header="true"
        class="my-2"
      >
        <template #default>
          <thead>
            <tr>
              <th class="text-left grey--text text--darken-1">
                ID
              </th>

              <th class="text-left grey--text text--darken-1">
                Name
              </th>

              <th class="text-left grey--text text--darken-1">
                Email
              </th>

              <th class="text-left grey--text text--darken-1">
                Advisors
              </th>

              <th class="text-left grey--text text--darken-1">
                Status
              </th>

              <th class="text-right grey--text text--darken-1">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="item in (usersStore.rppr.data )"
              :key="item.id"
            >
              <!--ID-->
              <td
                class="text-left"
                :class="$vuetify.theme.dark === false ? 'light-blue--text text--darken-4' : 'secondary--text'"
              >
                {{ item.id }}
              </td>
              <!--Name-->
              <td
                class="text-left"
                :class="$vuetify.theme.dark === false ? 'light-blue--text text--darken-4' : 'secondary--text'"
              >
                {{ item.name_reverse }}
              </td>
              <!--Email-->
              <td
                class="text-left"
                :class="$vuetify.theme.dark === false ? 'light-blue--text text--darken-4' : 'secondary--text'"
              >
                {{ item.email }}
              </td>
              <!--Advisors-->
              <td
                class="text-left"
                :class="$vuetify.theme.dark === false ? 'light-blue--text text--darken-4' : 'secondary--text'"
              >
                {{ item.advisors }}
              </td>
              
              <!--User Status-->
              <td
                class="text-left"
                :class="item.userStatus === 'Inactive' ? 'error--text' : $vuetify.theme.dark === false ? 'light-blue--text text--darken-4' : 'secondary--text'"
              >
                {{ item.userStatus }}
              </td>
              <td class="text-right light-blue--text text--darken-4">
                <!--Action Menu-->
                <v-menu
                  v-if="actions.menu === 1"
                  open-on-click
                  offset-y
                  left
                >
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      large
                      v-on="on"
                    >
                      <v-avatar
                        size="32px"
                        tile
                      >
                        <v-icon
                          color="primary"
                        >
                          fal fa-cog
                        </v-icon>
                      </v-avatar>
                    </v-btn>
                  </template>

                  <v-list>
                    <!--Action 1: View Detail-->
                    <v-list-item
                      @click.stop="openDetail(item.id)"
                    >
                      <v-list-item-title class="clickable primary--text">
                        <v-icon
                          color="primary"
                          left
                        >
                          fal fa-eye
                        </v-icon> View User
                      </v-list-item-title>
                      <v-divider />
                    </v-list-item>

                    <!--Action 2: Edit Record-->
                    <v-list-item
                      v-if="1 === 2"
                      @click.stop="openEntry(item.id)"
                    >
                      <v-list-item-title class="clickable primary--text">
                        <v-icon
                          color="primary"
                          left
                        >
                          fal fa-pencil-alt
                        </v-icon> Edit User
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-list
        v-else
        class="py-2"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="error--text text-subtitle-1 text-xs-center">
              No RPPR Users Exist
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';
import ParticipationEntry from '@/components/ParticipationEntry';
import User from '@/components/User';
import Pagination from '@/components/Pagination';
import ListBasic from '@/components/ListBasic';
import ExportExcel from '@/components/ExportExcel'

const { mapFields } = createHelpers({
  getterType: 'usersStore/getRPPRFilterField',
  mutationType: 'usersStore/updateRPPRFilterField',
});

export default {
  name: 'UsersRpprAdmin',
  components: {
    TitleBar,
    ParticipationEntry,
    User,
    Pagination,
    ListBasic,
    ExportExcel,
  },
  mixins: [mixin],
  props: {
     /*
     1 = Settings Users List,
     2 = RPPR List,
     3 = Project Detail Users List,
     4 = Trainees List (Researcher List Of Trainees),
     5 = Admin Researchers List,
     6 = Admin Trainees List,
     7 = Admin Others Project Users List,
     8 = Admin RPPR List,
     9 = Trainees Dashboard,
     10 = RPPR Researchers List,
     */
    section: Number,
  },
  data() {
    return {
      filterMenu: false,
      deleteDialog: false,
      actions: {
        menu:1,
        view:1,
        edit:0,
        password:1,
        invite:1,
        email:1,
        delete: 1
      },
    };
  },
  computed: {
    ...mapState({
      progressStore: state => state.progressStore,
      usersStore: state => state.usersStore,
      participantsStore: state => state.participantsStore,
      valueListsStore: state => state.valueListsStore,
    }),
    ...mapFields([
      'page',
      'sectionStored',
      'export',

			'activeUser',
			
			'id_project',
      'rppr_id',
      'rppr_name_first',
      'rppr_name_last',
      'rppr_flag_user_type',
      'email',
    ]),
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.dispatch('progressStore/set',true);

      const activeUser = this.$store.getters['usersStore/getActiveUser'];

      this.export = 0

      this.changeSectionCheck();

      let project = this.$store.getters['usersStore/getActiveProject'];
      this.id_project = project.id;
      this.rppr_flag_user_type = [8];

      this.activeUser = activeUser.id

      this.sectionStored = this.section;

      /* Grab filters */
      const filters = this.$store.getters['usersStore/getRPPRFilters'];
      /* Grab projects based on active filters */
      await this.$store.dispatch('usersStore/fetch', filters);

      this.$store.dispatch('progressStore/set',false)
    },

	  async filter(page) {
      this.filterMenu = false;
      this.page = page;
		  this.initialize();
    },
    
    clearFilterColumns() {
      this.rppr_id = '',
			this.rppr_name_first = '',
      this.rppr_name_last = '',
      this.email = '',
      this.page = 1;
    },

	  async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
		  this.initialize();
    },
    
    changeSectionCheck() {
      if(this.section !== this.sectionStored) {
        this.clearFilterColumns();
        this.$store.dispatch('usersStore/clearRppr');
      }
    },

	  async openEntry(id) {
		  await this.$store.dispatch('usersStore/entry', id);
    },

    async openDetail(id) {
      this.$store.dispatch('progressStore/set',true)

      await this.$store.dispatch('usersStore/read', id);
      
      this.$router.push({name: 'userDetail', params: { id: id } });
		},
		
		async rpprEntry(year,id,userID,projectID) {
      this.$store.dispatch('usersStore/resetRpprEntry');
      
      await this.$_loadValueLists(22);

      let data
      if(id) {
        data = {
          id_project: projectID,
          id_user: userID,
          id_year: year,
        }
        await this.$store.dispatch('participationStore/fetch', data);
        let participation = this.$store.getters['participationStore/getParticipation']
        this.$store.dispatch('usersStore/setParticipation', participation.data)
        await this.$store.dispatch('usersStore/rpprEntry', id);
      }
      else {
        let activeProject = this.$store.getters['usersStore/getActiveProject'];
        data = {
          id_project: activeProject.id,
          id_user: userID,
          id_year: year,
        }
        await this.$store.dispatch('participationStore/fetch', data);
        let participation = this.$store.getters['participationStore/getParticipation']
        this.$store.dispatch('usersStore/setParticipation', participation.data);
        await this.$store.dispatch('usersStore/newRpprEntry', data);
      }
    },
    
    showFiltered(){
      if(this.rppr_id || this.rppr_name_first || this.rppr_name_last || this.email) {
				return true;
			}
    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
